import React, { useEffect, useMemo } from 'react'
import { connect, useSelector } from "react-redux";
import { push } from 'connected-react-router'
import styled from 'styled-components'
import { getChallenges, isIdIdeal } from 'core'

import { signOut } from 'app/actions/auth'
import colors from 'app/lib/colors'
import { getMealPlan } from 'app/lib/util'
import * as constants from 'app/lib/constants'
import t from 'app/lib/i18n'
import { GrayHeader } from 'app/components/Core'
import DietTierBadge from 'app/components/DietTierBadge'
import { default as _Button } from 'app/components/Button'
import FoodGroupInfo from './components/FoodGroupInfo'
import DietCards from 'app/components/DietCards'
import DietDetails from 'app/components/DietDetails'
import Recipes from 'app/components/Recipes'
import ShoppingList from 'app/components/ShoppingList'
import SunbasketTransitionCTA from 'app/custom_partner_components/sunbasket/SunbasketTransitionCTA'
import NavigationPath from 'app/views/navigation/NavigationPath'
import ShareSheet from './components/ShareSheet'
import UserAccuracyRater from './components/UserAccuracyRater'
import NextButton from './NextButton'
import D2CCard from '../../../components/D2CCard'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 100px;
  max-width: ${constants.DESKTOP_MAX_CONTENT_WIDTH}px;
  margin: 0 auto;

  .disclaimer {
    font-size: 12px;
    color: ${colors.gray};
    margin: 40px 20px;
    text-align: center;
  }
`

const DietLabel = styled.div`
  display: inline-block;
  margin-bottom: 22px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.theme.primary};
  border-bottom: 1px solid ${(props) => colors.hexToRgb(props.theme.primary, 0.3)};
`

const BadgeWrap = styled.div`
  margin-top: 12px;
  margin-bottom: 20px;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  text-align: center;

  .badge-inner-wrap {
    position: relative;
    flex: 1;
  }

  .badge-inner-wrap + .badge-inner-wrap {
    margin-left: 30px;
    padding-left: 30px;

    :before {
      position: absolute;
      top: 30px;
      left: 0;
      height: 130px;
      display: block;
      content: '';
      border-left: 1px solid ${(props) => colors.hexToRgb(props.theme.primary, 0.3)};
    }
  }

  .ideal-info-label {
    font-size: 14px;
    text-align: center;
    margin-top: 8px;
  }
`

const Heading = styled.div`
  margin: 0 25px;
  margin-top: 25px;
  font-size: 14px;
  font-weight: bold;
  color: ${colors.darkGray};
`

const Button = styled(_Button)`
  margin-top: 20px;
  display: block;
`

const D2CHeader = styled(GrayHeader)`
  font-size: 1.8em;
  color: #196161;
`

const DietTransitionResults = (props) => {
  const { step, partner, onProgressChange, push, signOut, scrollRef, workflow } = props
  
  const nocalories = useSelector(state => state.general.viewCustomizations.nocalories)
  const nodetails = useSelector(state => state.general.viewCustomizations.nodetails)
  const signOutOnFinish = !!partner.sign_out_on_finish
  const showCompletionScreen = !!partner.show_completion_screen
  const redirectURI = localStorage.getItem('DIET_ID_REDIRECT')
  const nextUrl = redirectURI ?? partner.external_link_last_step_finish_button
  const hideComparison = partner?.bypass_id_vs_ideal_results_in_full_flow || false
  const idDiet = step.diet_id
  const idealDiet = step.diet_ideal
  const idIsIdeal = isIdIdeal(idDiet, idealDiet)
  const d2c = partner.code === 'd2cfreetrial'
  const showShareSheet = !!step.share_url
  
  const shareToken = useMemo(() => (step && step.workflowShare ? step.workflowShare : null), [step])
  
  
  const challenges = useMemo(
    () => (idDiet && idealDiet ? getChallenges(idDiet, idealDiet, step.diet_restrictions) : []),
    [idDiet, idealDiet, step.diet_restrictions]
  )

  useEffect(() => {
    onProgressChange('DietTransitionResults', 1)
  }, [])

  const onNextButtonClick = () => {
    const isLastStep = workflow.step.is_last_step
    if (signOutOnFinish) {
      signOut()
    }
    isLastStep && nextUrl && !partner.disable_ideal && idealDiet && idDiet ? window.open(nextUrl) : props.onSubmit(true)

    if (showCompletionScreen) {
      push('/complete')
    }
  }
  
  useEffect(() => {
    if(hideComparison){
      onNextButtonClick()
    }
  }, [hideComparison])

  const renderDietCards = () => {
    return (
      <div>
        {/*
        here6:
        {JSON.stringify(shareToken)}
        {JSON.stringify(workflow.step.workflow_id)}*/}
        {!nocalories && <DietCards
            idNutrition={step.diet_id_nutrition_info}
            idealNutrition={idIsIdeal ? null : step.diet_ideal_nutrition_info}
            goals={d2c ? null : step.goals}
            gender={(step.responses.user_info || {}).gender}
        />}
        {!nodetails && <DietDetails
            partner={partner}
            idNutrition={step.diet_id_nutrition_info}
            idealNutrition={idIsIdeal ? null : step.diet_ideal_nutrition_info}
            healthGoals={(step.responses.ideal_goals || {}).for_health}
            scrollRef={scrollRef}
            shareToken={shareToken}
            workflowID={workflow.step.workflow_id}
            
        />}
      </div>
    )
  }

  return (
    <Wrap>
      <BadgeWrap>
        <div className="badge-inner-wrap">
          <DietLabel>{partner.code === 'healthydining' ? t('Current') : t('Your ID')}</DietLabel>
          <DietTierBadge
            partner={partner}
            color={partner.primary_color}
            diet={idDiet}
            dietRestrictions={step.responses.diet_restriction_choices}
            showPhotoBelow
          />
          {step.diet_ideal_info_label && <div className="ideal-info-label">&nbsp;</div>}
        </div>
        <div className="badge-inner-wrap">
          <DietLabel>{partner.code === 'healthydining' ? t('Ideal') : t('Goal')}</DietLabel>
          <DietTierBadge
            partner={partner}
            color={partner.secondary_color}
            diet={idealDiet}
            dietRestrictions={step.responses.diet_restriction_choices}
            showPhotoBelow
          />
          {step.diet_ideal_info_label && <div className="ideal-info-label">{t(step.diet_ideal_info_label)}</div>}
          {idealDiet.code && !partner.is_tangelo && (
            <Button text="View Meal Plan" onClick={() => window.open(getMealPlan(idealDiet.code, partner.custom_meal_plans ? partner.code : false))} />
          )}
        </div>
      </BadgeWrap>
      {!idIsIdeal && !d2c && <Heading>{t(step.food_group_heading)}</Heading>}
      {!idIsIdeal && (
        <FoodGroupInfo
          highlightColor={partner.primary_color}
          includesTitle={t('increase')}
          food_groups_included={step.to_increase
            .map((v) => t(v))
            .slice(0, 4)
            .join(', ')}
          lacksTitle={t('decrease')}
          food_groups_lacking={step.to_decrease
            .map((v) => t(v))
            .slice(0, 4)
            .join(', ')}
          diet_restrictions={step.responses.diet_restriction_choices}
          d2c={d2c}
        />
      )}
      {/* temp disabled for prod */}
      {/*idealDiet && <Recipes diet_restrictions={step.responses.diet_restriction_choices} idealDiet={idealDiet} />*/}
      {d2c && (
        <>
          <NavigationPath idDiet={idDiet} idealDiet={idealDiet} challenges={challenges} partner={partner} d2c />
          <Recipes />
          <ShoppingList />
        </>
      )}
      {partner.ask_for_user_accuracy_rating_for_full_flow && <UserAccuracyRater ratingType="full_flow" />}
      {step.custom_sunbasket_cta && <SunbasketTransitionCTA {...step.custom_sunbasket_cta.props} />}
      {!d2c && <GrayHeader>{t('Your estimated Nutrient Profile')}</GrayHeader>}
      {d2c ? (
        <D2CCard>
          <D2CHeader>{t('Focus on improving these nutrients')}</D2CHeader>
          {renderDietCards()}
        </D2CCard>
      ) : (
        renderDietCards()
      )}
      {showShareSheet && <ShareSheet shareUrl={step.share_url} onClose={onNextButtonClick} partner={partner} />}
      {step.disclaimer && <div className="disclaimer">*{t(step.disclaimer)}</div>}
      {!showShareSheet && (
        <NextButton {...props} style={{ backgroundColor: partner.secondary_color }} onClick={onNextButtonClick} />
      )}
    </Wrap>
  )
}

export default connect(null, { push, signOut })(DietTransitionResults)
