import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from '../Button';
import { Divider } from '@blueprintjs/core';

const COOKIE_PREFERENCES_KEY = 'cookiePreferences';

const defaultPreferences = {
  essential: true,
  analytics: true,
  marketing: true,
};

const CookieBanner = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    z-index: 1300;
    border-top: 1px solid #ccc;
    background-color: white;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
`;

const Content = styled.div`
    max-width: 1200px;
    margin: 0 auto 30px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
`;

const PreferencesBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
`;

const PreferenceItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    background-color: #f7f7f7;
    border-radius: 8px;
`;

const Checkbox = styled.input`
    width: 18px;
    height: 18px;
`;

const StyledButton = styled.button`
    background-color: #1976d2;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: background 0.3s ease;

    &:hover {
        background-color: #115293;
    }

    &.outlined {
        background-color: white;
        color: #1976d2;
        border: 1px solid #1976d2;

        &:hover {
            background-color: #e3f2fd;
        }
    }
`;

const CookieConsent = () => {
  const [open, setOpen] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [preferences, setPreferences] = useState(defaultPreferences);
  
  useEffect(() => {
    const stored = localStorage.getItem(COOKIE_PREFERENCES_KEY);
    if (stored) {
      setPreferences(JSON.parse(stored));
      setOpen(false);
    }
  }, []);
  
  const handleAcceptAll = () => {
    const newPrefs = { essential: true, analytics: true, marketing: true };
    setPreferences(newPrefs);
    localStorage.setItem(COOKIE_PREFERENCES_KEY, JSON.stringify(newPrefs));
    setOpen(false);
  };
  
  const handleRejectAll = () => {
    const newPrefs = { essential: true, analytics: false, marketing: false };
    setPreferences(newPrefs);
    localStorage.setItem(COOKIE_PREFERENCES_KEY, JSON.stringify(newPrefs));
    setOpen(false);
  };
  
  const handleSavePreferences = () => {
    localStorage.setItem(COOKIE_PREFERENCES_KEY, JSON.stringify(preferences));
    setOpen(false);
  };
  
  const togglePreference = (key) => {
    setPreferences((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };
  
  if (!open) return null;
  
  return (
    <CookieBanner>
      <Content>
        <Row>
          <div style={{ flex: 1, minWidth: '60%' }}>
            <h3>Cookie Settings</h3>
            <p>
              We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic.
              By clicking "Accept All", you consent to our use of cookies.
            </p>
          </div>
          <ButtonGroup>
            <StyledButton onClick={handleAcceptAll}>Accept All</StyledButton>
            <StyledButton className="outlined" onClick={handleRejectAll}>Reject All</StyledButton>
            <StyledButton className="outlined" onClick={() => setExpanded(!expanded)}>
              {expanded ? 'Hide Preferences' : 'Customize'}
            </StyledButton>
          </ButtonGroup>
        </Row>
        
        {expanded && (
          <>
            <Divider />
            <PreferencesBox>
              <PreferenceItem>
                <div>
                  <h4>Essential Cookies</h4>
                  <p>These cookies are necessary for the website to function and cannot be switched off.</p>
                </div>
                <Checkbox type="checkbox" checked disabled />
              </PreferenceItem>
              <PreferenceItem>
                <div>
                  <h4>Analytics Cookies</h4>
                  <p>These cookies allow us to measure and improve the performance of our site.</p>
                </div>
                <Checkbox
                  type="checkbox"
                  checked={preferences.analytics}
                  onChange={() => togglePreference('analytics')}
                />
              </PreferenceItem>
              <PreferenceItem>
                <div>
                  <h4>Marketing Cookies</h4>
                  <p>These cookies are used to show relevant ads and improve marketing strategies.</p>
                </div>
                <Checkbox
                  type="checkbox"
                  checked={preferences.marketing}
                  onChange={() => togglePreference('marketing')}
                />
              </PreferenceItem>
            </PreferencesBox>
            <ButtonGroup>
              <StyledButton onClick={handleSavePreferences}>Save Preferences</StyledButton>
            </ButtonGroup>
          </>
        )}
      </Content>
    </CookieBanner>
  );
};

export default CookieConsent;
