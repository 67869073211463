import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { format } from 'date-fns'
import styled from 'styled-components'
import colors from 'app/lib/colors'
import { getUrlParam } from 'app/lib/util'
import FoodGroupInfo from 'app/views/Workflow/side-by-side/components/FoodGroupInfo'
import { GrayHeader } from 'app/components/Core'
import LoadingIndicator from 'app/components/LoadingIndicator'
import { default as _Button } from 'app/components/Button'
import DietTierBadge from 'app/components/DietTierBadge'
import DietCards from 'app/components/DietCards'
import DietDetails from 'app/components/DietDetails'
import { getMealPlan } from 'app/lib/util'
import * as constants from 'app/lib/constants'
import t from 'app/lib/i18n'
import * as actions from 'app/actions/shareDashboard'
import { UserAssessmentDetails } from '../Reporting/UserAssessment'
import { getCurrentState } from "../../actions/workflow";

const Button = styled(_Button)``

const LoadingWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const Wrap = styled.div`
  color: ${colors.veryDarkGray};

  .disclaimer {
    font-size: 12px;
    color: ${colors.gray};
    margin: 40px 20px;
    text-align: center;
  }

  .buttonWrap {
    margin: 0 30px;
  }

  .button {
    cursor: pointer;
    text-align: center;
    padding: 12px;
    background: ${(props) => props.theme.primary};
    color: white;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 18px;
    font-weight: bold;
    max-width: 320px;

    &.secondary {
      background: ${(props) => props.theme.secondary};
    }
  }

  .powered-by {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    margin-top: 15px;

    @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
      display: none;
    }
  }
`

const DietLabel = styled.div`
  display: inline-block;
  margin-bottom: 22px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${(props) => props.theme.primary};
  border-bottom: 1px solid ${(props) => colors.hexToRgb(props.theme.primary, 0.3)};
`

const BadgeWrap = styled.div`
  margin-top: 12px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;

  .inner-wrap {
    position: relative;
  }

  .inner-wrap + .inner-wrap {
    margin-left: 30px;
    padding-left: 30px;

    :before {
      position: absolute;
      top: 30px;
      left: 0;
      height: 130px;
      display: block;
      content: '';
      border-left: 1px solid ${(props) => colors.hexToRgb(props.theme.primary, 0.3)};
    }
  }

  .share-icon {
    position: absolute;
    top: 25px;
    right: 25px;
    color: ${(props) => props.theme.primary};
    font-size: 25px;
    cursor: pointer;
  }

  .share-info {
    z-index: 999;
    background-color: ${colors.white};
    position: absolute;
    top: 25px;
    right: 25px;
    padding: 32px;
    border: 1px ${colors.gray} solid;
    border-radius: 8px;

    .close-button {
      position: absolute;
      top: -12px;
      right: -12px;
      color: ${colors.veryDarkGray};
      font-size: 26px;
      background-color: ${colors.white};
    }

    .desc {
      color: ${colors.gray};
      margin-bottom: 5px;
    }

    input {
      width: 280px;
      padding: 5px;
      border: 1px ${colors.gray} solid;
    }
  }

  .button {
    display: ${(props) => (props.showButtons ? 'block' : 'none')};
    cursor: pointer;
    text-align: center;
    padding: 12px;
    background: ${(props) => props.theme.primary};
    color: white;
    border-radius: 8px;
    margin-top: 18px;
    font-weight: bold;

    &.secondary {
      background: ${(props) => props.theme.secondary};
    }
  }

  .meal-plan-button {
    margin-left: 260px;
  }
`

const Heading = styled.div`
  margin: 25px;
  font-size: 18px;
  color: ${colors.veryDarkGray};
  border-bottom: 1px ${colors.veryDarkGray} solid;
  text-align: center;
  padding: 8px 0;

  .sub-heading {
    display: block;
    font-size: 14px;
    margin-top: 4px;
  }
`

const Logo = styled.img`
  display: block;
  margin: 15px auto 0px;
  max-width: 120px;
`

const Dashboard = (props) => {
  const { partner, shareDashboard, user, dietIdeal, dispatch } = props
  useEffect(() => {
    dispatch(actions.init())
  }, [])
  const share = shareDashboard.workflowShare
  if (getUrlParam('detailed')) {
    return <UserAssessmentDetails partner={partner} userAssessment={shareDashboard} dispatch={dispatch} user={user} />
  }

  if (shareDashboard.initializing) {
    return (
      <LoadingWrap>
        <LoadingIndicator />
      </LoadingWrap>
    )
  }

  const pronoun = user.first_name ? `${user.first_name}'s` : 'My'
  const noun = partner.code === 'madeeasymeals' ? 'Healthy Eating Score' : dietIdeal ? 'Diet ID & IDEAL' : 'Diet ID'

  const created = format(new Date(share.created_at), 'MMMM Do YYYY')

  return (
    <Wrap>
      <Logo src={partner.logo_small_url} />
      {partner.show_powered_by_diet_id && <div className="powered-by">{t('Powered by Diet ID')}</div>}
      <Heading>
        {pronoun} {noun}
        <div className="sub-heading">{created}</div>
      </Heading>
      <BadgeWrap showButtons={false}>
        <div className="inner-wrap">
          <DietLabel>{t('Your ID')}</DietLabel>
          <DietTierBadge
            partner={partner}
            color={partner.primary_color}
            diet={share.diet_id}
            dietRestrictions={share.diet_restrictions}
            showPhotoBelow
          />
        </div>
        {share.diet_ideal && (
          <div className="inner-wrap" key="badge">
            <DietLabel>{t('Goal')}</DietLabel>
            <DietTierBadge
              partner={partner}
              color={partner.secondary_color}
              diet={share.diet_ideal}
              dietRestrictions={share.diet_restrictions}
              showPhotoBelow
            />
          </div>
        )}
      </BadgeWrap>
      {share.diet_ideal && !partner.is_tangelo && (
        <BadgeWrap showButtons={false}>
          <div className="meal-plan-button">
            <Button text="View Meal Plan" onClick={() => window.open(getMealPlan(share.diet_ideal.code, partner.custom_meal_plans ? partner.code : false))} />
          </div>
        </BadgeWrap>
      )}
      {share && share.diet_ideal && share.to_increase && share.to_decrease && (
        <FoodGroupInfo
          highlightColor={partner.primary_color}
          includesTitle="increase"
          food_groups_included={share.to_increase
            .map((v) => t(v))
            .slice(0, 4)
            .join(', ')}
          lacksTitle="decrease"
          food_groups_lacking={share.to_decrease
            .map((v) => t(v))
            .slice(0, 4)
            .join(', ')}
          diet_restrictions={share.diet_restrictions}
        />
      )}
      <GrayHeader>{t('Estimated Nutrient Profile')}</GrayHeader>
      <DietCards
        idNutrition={share.diet_id_nutrition_info}
        idealNutrition={share.diet_ideal_nutrition_info}
        goals={share.goals}
        gender={share.user_info.gender}
      />
      <DietDetails
        partner={partner}
        idNutrition={share.diet_id_nutrition_info}
        idealNutrition={share.diet_ideal_nutrition_info}
        healthGoals={share.goals.map((goal) => goal.value)}
        scrollRef={() => window}
        shareToken={share.token}
      />
      {shareDashboard.disclaimer && <div className="disclaimer">*{t(shareDashboard.disclaimer)}</div>}
    </Wrap>
  )
}

const mapStateToProps = (state) => ({
  shareDashboard: state.shareDashboard,
  partner: state.shareDashboard.partnerId ? state.data.partner[state.shareDashboard.partnerId] : null,
  user: state.shareDashboard.userId ? state.data.user[state.shareDashboard.userId] : null,
  data: state.data
})

export default connect(mapStateToProps)(Dashboard)
