import { apiCall } from 'app/lib/api'
import * as dataActions from 'app/actions/data'
import * as workflowActions from 'app/actions/workflow'
import * as generalActions from 'app/actions/general'
import * as constants from 'app/lib/constants'

export const AUTH_SCREEN_SIGN_UP = 'AUTH_SCREEN_SIGN_UP'
export const AUTH_SCREEN_SIGN_IN = 'AUTH_SCREEN_SIGN_IN'

export const AUTH_SCREEN_RESET_PASSWORD = 'AUTH_SCREEN_RESET_PASSWORD'
export const AUTH_SCREEN_RESET_PASSWORD_DONE = 'AUTH_SCREEN_RESET_PASSWORD_DONE'
export const AUTH_SCREEN_UPDATE_PASSWORD = 'AUTH_SCREEN_UPDATE_PASSWORD'
export const AUTH_SCREEN_UPDATE_PASSWORD_DONE = 'AUTH_SCREEN_UPDATE_PASSWORD_DONE'

export const AUTH_CHANGE_SCREEN = 'AUTH_CHANGE_SCREEN'

export const AUTH_SIGN_UP = 'AUTH_SIGN_UP'
export const AUTH_SIGN_UP_DONE = 'AUTH_SIGN_UP_DONE'
export const AUTH_SIGN_UP_FAILED = 'AUTH_SIGN_UP_FAILED'

export const AUTH_SIGN_IN = 'AUTH_SIGN_IN'
export const AUTH_SIGN_IN_DONE = 'AUTH_SIGN_IN_DONE'
export const AUTH_SIGN_IN_FAILED = 'AUTH_SIGN_IN_FAILED'

export const AUTH_SCREEN_ENTER_OTP = 'AUTH_SCREEN_ENTER_OTP'
export const AUTH_SCREEN_ENTER_OTP_WITHOUT_EMAIL = 'AUTH_SCREEN_ENTER_OTP_WITHOUT_EMAIL'
export const AUTH_SCREEN_ENTER_OTP_DONE = 'AUTH_SCREEN_ENTER_OTP_DONE'

export const AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD'
export const AUTH_RESET_PASSWORD_DONE = 'AUTH_RESET_PASSWORD_DONE'
export const AUTH_RESET_PASSWORD_FAILED = 'AUTH_RESET_PASSWORD_FAILED'

export const AUTH_UPDATE_PASSWORD = 'AUTH_UPDATE_PASSWORD'
export const AUTH_UPDATE_PASSWORD_DONE = 'AUTH_UPDATE_PASSWORD_DONE'
export const AUTH_UPDATE_PASSWORD_FAILED = 'AUTH_UPDATE_PASSWORD_FAILED'

export const AUTH_REAUTHENTICATE = 'AUTH_REAUTHENTICATE'
export const AUTH_REAUTHENTICATE_DONE = 'AUTH_REAUTHENTICATE_DONE'
export const AUTH_REAUTHENTICATE_FAILED = 'AUTH_REAUTHENTICATE_FAILED'

export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT'
export const AUTH_SIGN_OUT_DONE = 'AUTH_SIGN_OUT_DONE'
export const AUTH_SIGN_OUT_FAILED = 'AUTH_SIGN_OUT_FAILED'

export const AUTH_SCREEN_TOS = 'AUTH_SCREEN_TOS'
export const AUTH_SCREEN_TOS_UNCHECKED = 'AUTH_SCREEN_TOS_UNCHECKED'

export const AUTH_SCREEN_PARENTAL_CONSENT = 'AUTH_SCREEN_PARENTAL_CONSENT'
export const AUTH_SCREEN_PARENTAL_CONSENT_UNCHECKED = 'AUTH_SCREEN_PARENTAL_CONSENT_UNCHECKED'

function storeAuthInfoLocally(user, authToken, navUserId, partnerToken) {
  if (partnerToken) {
    window.localStorage.setItem(constants.LOCAL_STORAGE_PARTNER_TOKEN_KEY, partnerToken)
  }

  window.localStorage.setItem(
    constants.LOCAL_STORAGE_AUTH_KEY,
    JSON.stringify({
      user_id: user.id,
      partner_id: user.partner_id,
      nav_user_id: navUserId,
      auth_token: authToken
    })
  )
}

export function signOut(fields) {
  return async (dispatch, getState) => {
    window.localStorage.removeItem(constants.LOCAL_STORAGE_AUTH_KEY)
    dispatch({ type: AUTH_SIGN_OUT })
    dispatch({ type: AUTH_SCREEN_ENTER_OTP_DONE })
  }
}

export const resetPassword = (email) => async (dispatch, getState) => {
  dispatch({ type: AUTH_RESET_PASSWORD })

  apiCall('password_resets', {
    method: 'POST',
    data: { password_reset: { email } }
  }).then(
    (resp) => dispatch({ type: AUTH_RESET_PASSWORD_DONE }),
    (failure) => {
      dispatch({
        type: AUTH_RESET_PASSWORD_FAILED,
        errors: failure.json.errors || {}
      })
    }
  )
}

export const updatePassword = (resetToken, email, password, passwordConfirmation) => async (dispatch, getState) => {
  dispatch({ type: AUTH_UPDATE_PASSWORD })

  apiCall('password_resets/update', {
    method: 'POST',
    data: {
      reset_token: resetToken,
      email,
      user: { password, password_confirmation: passwordConfirmation }
    }
  }).then(
    (resp) => dispatch({ type: AUTH_UPDATE_PASSWORD_DONE }),
    (failure) => {
      dispatch({
        type: AUTH_UPDATE_PASSWORD_FAILED,
        errors: failure.json.errors || {}
      })
    }
  )
}

export function signUp(fields) {
  return async (dispatch, getState) => {
    dispatch({
      type: AUTH_SIGN_UP
    })

    // Make our api call, hoping to get info back a newly created user
    apiCall('users', {
      method: 'POST',
      data: {
        user: Object.assign(fields, {
          partner_code: getState().general.partnerCode,
          utm: getUTMParams()
        })
      }
    }).then(
      (resp) => {
        // If response doesn't contain errors we succeeded
        if (resp.status == 'user_exists') {
          dispatch({
            type: AUTH_SIGN_UP_FAILED,
            errors: {
              phone: ['number already in use, please Login.']
            }
          })
          //dispatch(changeScreen(AUTH_SCREEN_SIGN_IN))
        } else if (resp.status == 'sms_sent') {
          dispatch({ type: AUTH_SIGN_UP_FAILED })
          //dispatch(changeScreen(AUTH_SCREEN_SIGN_IN))
          dispatch({ type: AUTH_SCREEN_ENTER_OTP_WITHOUT_EMAIL, serviceId: resp.service_id })
        } else {
          dispatch(receiveAuthResponse(resp.user, resp.auth_token, resp.nav_user_id))
          dispatch({
            type: AUTH_SIGN_UP_DONE,
            user: resp.user,
            navUserId: resp.nav_user_id,
            authToken: resp.auth_token
          })
          dispatch(workflowActions.getCurrentState())
        }
      },
      (failure) => {
        // Request failed for unknown reason, handle error here
        dispatch({
          type: AUTH_SIGN_UP_FAILED,
          errors: failure.json.errors ? failure.json.errors : {}
        })
      }
    )
  }
}

export function receiveAuthResponse(user, authToken, navUserId, partnerToken) {
  return async (dispatch, getState) => {
    storeAuthInfoLocally(user, authToken, navUserId, partnerToken)
    dispatch(
      dataActions.setData('user', {
        [user.id]: user
      })
    )
  }
}

export function signIn(partnerId, email, password, access_code = null, otp = null, appCode = null, phone = null) {
  return async (dispatch, getState) => {
    dispatch({
      type: AUTH_SIGN_IN
    })

    // Make our api call, hoping to get info back about partner and user
    apiCall('sessions', {
      method: 'POST',
      data: {
        user: {
          partnerId,
          email: phone ? null : email,
          password,
          access_code,
          otp,
          app_code: appCode,
          phone_number: phone ? '+1' + phone : null,
          utm: getUTMParams()
        }
      }
    }).then(
      (resp) => {
        // If response doesn't contain errors we succeeded
        dispatch(receiveAuthResponse(resp.user, resp.auth_token, resp.nav_user_id, resp.partner_token))

        if (resp.partner_token) {
          dispatch(generalActions.init({ partner_token: resp.partner_token }))
        }

        dispatch({
          type: AUTH_SIGN_IN_DONE,
          user: resp.user,
          navUserId: resp.nav_user_id,
          authToken: resp.auth_token
        })

        if (window.location.pathname !== '/admin_page') {
          dispatch({ type: generalActions.SHOW_ADMIN_LANDING_PAGE })
        }

        dispatch(workflowActions.getCurrentState())
      },
      (failure) => {
        // Request failed or enter OTP prompt
        if (failure.status === 302) {
          dispatch({ type: AUTH_SIGN_IN_FAILED })
          dispatch({ type: AUTH_SCREEN_ENTER_OTP })
        } else {
          dispatch({
            type: AUTH_SIGN_IN_FAILED,
            errors: failure.json.errors ? failure.json.errors : {}
          })
        }
      }
    )
  }
}
export function signInVirginPulse() {
  return async (dispatch, getState) => {
    // Redirect to their login page
    apiCall('vp-sign-in', {
      method: 'POST',
      data: {
        redirect_uri: window.location.href.split('?')[0]
      }
    }).then((resp) => (window.location = resp.loginUrl))
  }
}
export function signInEpic(partnerId) {
  return async (dispatch, getState) => {
    // Redirect to their login page
    apiCall('epic/oauth_authorize_url', {
      method: 'POST',
      data: {
        redirect_uri: window.location.href.split('?')[0]
      }
    }).then((resp) => (window.location = resp.loginUrl))
  }
}
export function saveEpicSession(sessionCode, partnerId) {
  return async (dispatch, getState) => {
    dispatch({
      type: AUTH_SIGN_IN
    })

    // Make our api call, hoping to get info back about virgin pulse user
    apiCall('epic', {
      method: 'POST',
      data: {
        session: {
          code: sessionCode,
          partner: partnerId,
          redirect_uri: window.location.href.split('?')[0]
        }
      }
    }).then(
      (resp) => {
        // If response doesn't contain errors we succeeded
        dispatch(receiveAuthResponse(resp.user, resp.auth_token, resp.nav_user_id, resp.partner_token))

        if (resp.partner_token) {
          dispatch(generalActions.init({ partner_token: resp.partner_token }))
        }

        dispatch({
          type: AUTH_SIGN_IN_DONE,
          user: resp.user,
          navUserId: resp.nav_user_id,
          authToken: resp.auth_token
        })

        dispatch(workflowActions.getCurrentState(true))
      },
      (failure) => {
        // Request failed or enter OTP prompt
        if (failure.status === 302) {
          dispatch({ type: AUTH_SIGN_IN_FAILED })
          dispatch({ type: AUTH_SCREEN_ENTER_OTP })
        } else {
          dispatch({
            type: AUTH_SIGN_IN_FAILED,
            errors: failure.json.errors ? failure.json.errors : {}
          })
        }
      }
    )
  }
}
export function saveVirginPulseSession(sessionState, sessionCode, partnerId) {
  return async (dispatch, getState) => {
    dispatch({
      type: AUTH_SIGN_IN
    })

    // Make our api call, hoping to get info back about virgin pulse user
    apiCall('virgin_pulse', {
      method: 'POST',
      data: {
        session: {
          state: sessionState,
          code: sessionCode,
          partner: partnerId,
          redirect_uri: window.location.href.split('?')[0]
        }
      }
    }).then(
      (resp) => {
        // If response doesn't contain errors we succeeded
        dispatch(receiveAuthResponse(resp.user, resp.auth_token, resp.nav_user_id, resp.partner_token))

        if (resp.partner_token) {
          dispatch(generalActions.init({ partner_token: resp.partner_token }))
        }

        dispatch({
          type: AUTH_SIGN_IN_DONE,
          user: resp.user,
          navUserId: resp.nav_user_id,
          authToken: resp.auth_token
        })

        dispatch(workflowActions.getCurrentState(true))
      },
      (failure) => {
        // Request failed or enter OTP prompt
        if (failure.status === 302) {
          dispatch({ type: AUTH_SIGN_IN_FAILED })
          dispatch({ type: AUTH_SCREEN_ENTER_OTP })
        } else {
          dispatch({
            type: AUTH_SIGN_IN_FAILED,
            errors: failure.json.errors ? failure.json.errors : {}
          })
        }
      }
    )
  }
}

export function reauthenticate(partnerId, userId, authToken) {
  return async (dispatch, getState) => {
    dispatch({
      type: AUTH_REAUTHENTICATE
    })

    // Make our api call, hoping to get info back about user
    apiCall('sessions', {
      method: 'POST',
      data: {
        user: {
          id: userId,
          auth_token: authToken
        }
      }
    }).then(
      (resp) => {
        // If response doesn't contain errors we succeeded
        dispatch(
          dataActions.setData('user', {
            [resp.user.id]: resp.user
          })
        )
        dispatch({
          type: AUTH_REAUTHENTICATE_DONE,
          partnerId: partnerId,
          userId: resp.user.id,
          navUserId: resp.nav_user_id,
          authToken
        })
        dispatch(workflowActions.getCurrentState())
      },
      (failure) => {
        // Request failed
        dispatch({
          type: AUTH_REAUTHENTICATE_FAILED,
          errors: failure.json.errors ? failure.json.errors : {}
        })
        
      }
    )
  }
}

export function changeScreen(screen) {
  return {
    type: AUTH_CHANGE_SCREEN,
    screen
  }
}
export const getUTMParams = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return {
    source: urlParams.get('utm_source') || urlParams.get('source') || '',
    medium: urlParams.get('utm_medium') || urlParams.get('medium') || '',
    campaign: urlParams.get('utm_campaign') || urlParams.get('campaign') || '',
    term: urlParams.get('utm_term') || urlParams.get('term') || '',
    content: urlParams.get('utm_content') || urlParams.get('content') || ''
  }
}
