import React from 'react'
import { View, Platform, StyleSheet, SafeAreaView } from 'react-native'
import truncate from 'lodash/truncate'
import Device from 'app/fs/lib/Device'
import { connect } from 'react-redux'
import { colors } from 'app/fs/lib/styles'
import Loading from 'app/fs/components/Loading'
import Carousel from 'app/fs/components/Carousel'
import Challenge from 'app/fs/models/Challenge'

import FS from 'app/fs/components/FS'

import {
  switchView,
  ONBOARDING_VIEW_KEY_TOUR,
  ONBOARDING_VIEW_KEY_SIGNIN,
  ONBOARDING_VIEW_KEY_SIGNUP
} from 'app/fs/actions/onboarding/base'

import { signInResetState } from 'app/fs/actions/onboarding/signin'

import { getAllChallenges } from 'app/fs/actions/challenges'

let logoDims = { width: 603, height: 163 }
let logoW = Math.floor(Math.min(Device.width - 100, logoDims.width))
let logoH = Math.floor((logoW / logoDims.width) * logoDims.height)

class SplashScreen extends FS.View {
  getMyUrl() {
    return 'onboarding/splash'
  }

  constructor(props) {
    super(props)
  }

  componentWillMount() {
    this.props.dispatch(signInResetState())
    if (Challenge.all().length === 0) {
      this.props.dispatch(getAllChallenges())
    }
  }

  doSignUpEmail = () => {
    this.props.dispatch(switchView(ONBOARDING_VIEW_KEY_SIGNUP))
  }

  doSignIn = () => {
    this.props.dispatch(switchView(ONBOARDING_VIEW_KEY_SIGNIN))
  }

  handleCarouselInitialization = scroller => {
    scroller.scrollToPage(1)
  }

  renderLoading() {
    return <Loading style={styles.loading} />
  }

  renderCategories() {
    var categories = Challenge.byCategory()
    var filteredCategories = categories.map(categoryGroup => {
        return {
          category: categoryGroup.category,
          challenges: categoryGroup.challenges.filter(
            challenge => challenge.name.toLowerCase() !== 'make lunch' && challenge.name.toLowerCase() !== 'cook dinner'
          )
        }
      })
      .filter(categoryGroup => categoryGroup.challenges.length > 0) // Remove empty categories if needed

    var icons = {
      1: 'eatLessProcFood',
      2: 'eatFewAnimal',
      3: 'eatMorePlants',
      4: 'eatMoreMindfull'
    }

    if (categories && categories.length > 0) {
      return (
        <Carousel
          onPageChange={this.handlePageChange}
          containerStyles={{ flex: 1 }}
          page={1}
          totalPages={categories.length}
          onInitialization={this.handleCarouselInitialization}
          paginationInset={10}
          paginationProps={{
            defaultFillColor: 'transparent',
            activeFillColor: colors.challengeGreen,
            borderColor: colors.challengeGreen,
            borderWidth: 0.5,
            spacing: 6,
            radius: 6
          }}
        >
          {categories.map(cat => (
            <View style={styles.category} key={cat.category.id}>
              <View style={styles.categoryImageWrap}>
                <FS.Image
                  style={styles.categoryImage}
                  source={{
                    uri:
                      'https://foodstand.imgix.net/assets/images/challenge_icons/' + icons[cat.category.id] + '@3x.png'
                  }}
                  imgixAttrs={{ w: 150, h: 150, fit: 'fill', bg: colors.white }}
                />
              </View>
              <FS.Text style={styles.categoryName} numberOfLines={1}>
                {cat.category.name}
              </FS.Text>
              <View style={styles.challengeNames}>
                {cat.challenges.map((challenge, idx) => {
                  if (idx < 3) {
                    let name = challenge.name.toUpperCase()
                    name = Platform.OS === 'web' ? truncate(name, { length: 30 }) : name
                    return (
                      <FS.Text style={styles.categoryChallengeName} numberOfLines={1} key={challenge.id}>
                        {name}
                      </FS.Text>
                    )
                  }
                })}
              </View>
            </View>
          ))}
        </Carousel>
      )
    } else {
      return this.renderLoading()
    }
  }

  render() {
    return (
      <SafeAreaView style={styles.container}>
        <View style={styles.mainArea}>Oops! Something went wrong. Please try again.</View>
      </SafeAreaView>
    )
  }
}

function mapStateToProps(state) {
  //Take all of state.onboarding as starating point and add/adjust from there
  return Object.assign({}, state.onboarding.base, {
    challenges: state.challenges
  })
}

export default connect(mapStateToProps)(SplashScreen)

var styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.white,
    justifyContent: 'flex-start'
  },
  mainArea: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 20
  },
  logoArea: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 20
  },
  logoImage: {
    width: logoW,
    height: logoH
  },
  aboutText: {
    color: colors.gray,
    opacity: 0.8,
    letterSpacing: 1.3,
    fontSize: 14,
    marginTop: 20
  },
  category: {
    width: Device.width,
    //height: Device.width,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 30
  },
  categoryImageWrap: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  categoryImage: {
    width: 150,
    height: 150,
    paddingVertical: 30
  },
  categoryName: {
    marginTop: 10,
    color: colors.darkGray,
    paddingHorizontal: 20,
    fontSize: 20,
    textAlign: 'center',
    fontWeight: '500'
  },
  challengeNames: {
    marginTop: 8,
    paddingHorizontal: 20,
    marginBottom: 10
  },
  categoryChallengeName: {
    color: colors.gray,
    opacity: 0.9,
    letterSpacing: 1.3,
    fontSize: 14,
    marginTop: 1,
    textAlign: 'center'
  },
  buttonArea: {
    paddingTop: 20,
    paddingHorizontal: 0,
    justifyContent: 'center',
    alignItems: 'center'
  },
  goButton: {
    backgroundColor: colors.challengeGreen,
    paddingHorizontal: 60,
    marginHorizontal: 10,
    paddingVertical: 15,
    flexDirection: 'row',
    borderRadius: 8,
    alignItems: 'center'
  },
  goButtonIcon: {
    color: colors.white,
    fontSize: 20,
    marginRight: 10
  },
  goButtonText: {
    color: colors.white,
    fontSize: 16,
    textAlign: 'center',
    fontWeight: '600'
  },
  signInButton: {
    padding: 20
  },
  loading: {
    flex: 1,
    padding: 40,
    opacity: 0.7
  },
  signInButtonText: {
    fontWeight: '600',
    fontSize: 14,
    opacity: 0.9
  }
})
