import './App.css'
import '../assets/styles/ionicons.min.css'
import 'animate.css'
import 'normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'

import React, { useState, useEffect, useMemo } from "react";
import { Provider, connect } from 'react-redux'
import { Route, Switch } from 'react-router'
import { ConnectedRouter } from 'connected-react-router'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { Device } from '@capacitor/device'
import _ from 'lodash'
import * as Font from 'expo-font'

import * as constants from 'app/lib/constants'
import { onLocaleChange } from 'app/lib/i18n'
import { init as initIntegration } from 'app/lib/integration'
import { history, store } from 'app/lib/redux'
import { getUrlParam } from 'app/lib/util'
import * as generalActions from 'app/actions/general'
import { saveVirginPulseSession, saveEpicSession } from 'app/actions/auth'
import InternationalizationBar from 'app/components/InternationalizationBar'
import LocaleAlert from 'app/components/LocaleAlert'
import StatusBar from 'app/components/StatusBar'
import AppInitialization from 'app/views/AppInitialization'
import InvalidPartnerCode from 'app/views/InvalidPartnerCode'
import InitializationError from 'app/views/InitializationError'
import Analytics from 'app/lib/analytics'
import Authentication from 'app/views/Authentication'
import Main from 'app/views/Main'
import Landing from 'app/views/Landing'
import NativeCTA from 'app/views/NativeCTA'
import ShareDashboard from 'app/views/ShareDashboard'
import UserAssessment from 'app/views/reporting/UserAssessment'
import Unsubscribe from 'app/views/Unsubscribe'

import { getTheme as getWellcoachesTheme } from 'app/custom/wellcoaches'
import { QueryClient, QueryClientProvider } from "react-query";
import { postCurrentLocale } from "./lib/api/postCurrentLocale";
import { setRedirectUri } from "./actions/general";
import { API_HOST_V3 } from "core/src/lib/constants";
import { signOut } from "./actions/auth";
import CookieConsent from "./components/CookieConsent";

const GlobalStyles = createGlobalStyle`
  a,
  a:hover,
  a:visited{
    color: ${(props) => props.theme.primary};
  }

  body.nochat-view, body.hide-chat{
    #beacon-container,
    #beacon-container-body,
    #hubspot-messages-iframe-container{
    display: none !important;
    }
  }
  body.nolocale-view{
    .weglot-container.wg-default{
    display: none !important;
    }
  }

  svg.svg-inline--fa{
    box-sizing: initial;
  }

  .bp3-datepicker .DayPicker-Day.DayPicker-Day--selected{
    background-color: ${(props) => props.theme.primary};
  }
`

const NaturesBountyStyles = createGlobalStyle`
  body {
    font-family: Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
`

const Page = ({ partner, user, general, auth, workflow, shareDashboard, userAssessment }) => {
  useEffect(() => {
    if (partner && ['/kiosk'].includes(window.location.pathname.toLowerCase())) {
      console.log('Kiosk Redirect', partner)
      const kioskCode = new URLSearchParams(window.location.search).get('access_code') || localStorage.getItem('kioskCode')
      if(kioskCode){
        localStorage.setItem('kioskCode', kioskCode)
      }
      const url = API_HOST_V3 + `/kiosk?partner_code=${partner.code}&access_code=${kioskCode}`;
      fetch(url)
        .then(response => response.json()) // Convert the response to JSON
        .then(data => {
          if (data.success && data.url) {
            console.log('Logging out...')
            signOut()
            window.location.href = data.url; // Redirect to the URL from the response
          } else {
            console.error('Failed to get valid response:', data);
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  },[partner, user])
  
  // console.log('Here is partner: ', partner)
  if (isUnsubscribeURL()) {
    return <Unsubscribe url={window.location.href} />
  }

  if (general.initializing || auth.reauthenticating || workflow.gettingCurrState || general.initializingPartnerUser) {
    return <AppInitialization />
  }

  if (userAssessment.userAssessmentToken) {
    const theme = {
      primary:
        userAssessment.workflowShare && userAssessment.workflowShare.partner
          ? userAssessment.workflowShare.partner.primary_color
          : (partner || {}).primary_color,
      secondary:
        userAssessment.workflowShare && userAssessment.workflowShare.partner
          ? userAssessment.workflowShare.partner.secondary_color
          : (partner || {}).secondary_color
    }

    return (
      <ThemeProvider theme={theme}>
        <UserAssessment />
      </ThemeProvider>
    )
  }

  if (shareDashboard.shareToken) {
    const theme = {
      primary:
        shareDashboard.workflowShare && shareDashboard.workflowShare.partner
          ? shareDashboard.workflowShare.partner.primary_color
          : (partner || {}).primary_color,
      secondary:
        shareDashboard.workflowShare && shareDashboard.workflowShare.partner
          ? shareDashboard.workflowShare.partner.secondary_color
          : (partner || {}).secondary_color
    }

    return (
      <ThemeProvider theme={theme}>
        <ShareDashboard />
      </ThemeProvider>
    )
  }

  if (partner && user) {
    // User already logged in, show them diet id UI
    return <Main />
  }

  if (partner && partner.code === 'd2cfreetrial' && !user && getUrlParam('auth')) {
    return <Authentication />
  }

  if (
    partner &&
    !user &&
    !general.landingPageAcknowledged &&
    !getUrlParam('reset_token') &&
    !getUrlParam('session_state') &&
    !general.viewCustomizations.nolanding &&
    partner.code !== 'montefiore'
  ) {
    return <Landing />
  }

  if (partner && !user) {
    // Show sign in flow
    return <Authentication />
  }

  if (general.invalidPartnerCode) {
    return <InvalidPartnerCode />
  }

  return <InitializationError />
}

const updateViewCustomizationClasses = (customizations) => {
  Object.entries(customizations).forEach(([view, active]) => {
    document.body.classList[active ? 'add' : 'remove'](`${view}-view`)
  })
}

const App = (props) => {
  
  const {
    partner,
    general: { partnerId, viewCustomizations }
  } = props

  const [refreshing, setRefreshing] = useState(false)
  const [localeParam, setLocaleParam] = useState(undefined)
  const [redirectUri, setRedirectUri] = useState(undefined)
  const [noLocale, setNoLocale] = useState(false)

  const nbty = partner && partner.code === 'nbty'
  const wellcoaches = partner && partner.code.includes('wellcoaches')

  const [deviceInfo, setDeviceInfo] = useState({})

  useEffect(() => {
    ;(async () => {
      setDeviceInfo(await Device.getInfo())
    })()
  }, [])

  useEffect(() => {
    Analytics.trackPageView(window.location.pathname + window.location.hash.replace('#/', ''))

    initIntegration()
  }, [])

  useEffect(
    () =>
      onLocaleChange(() => {
        setRefreshing(true)

        setTimeout(() => {
          setRefreshing(false)
        }, 1)
      }),
    []
  )
  useEffect(() => {
    const locale = getUrlParam('locale')
    setLocaleParam(locale)
    if (locale) {
      props.dispatch(generalActions.setLocale(locale))
    }
  }, [window.location.href, localeParam])
  useEffect(() => {
    const url = window.location.href;
    const redirectUri = getUrlParam('redirectUri')
    setRedirectUri(redirectUri)
    if (redirectUri) {
      props.dispatch(generalActions.setRedirectUri(redirectUri))
    }
  }, [window.location.href, redirectUri])
  useEffect(() => {
    const sessionState = getUrlParam('session_state') || false
    const sessionCode = getUrlParam('code')
    if (sessionState && sessionCode && partnerId) {
      props.dispatch(saveVirginPulseSession(sessionState, sessionCode, partnerId))
    }
    if (!sessionState && sessionCode && partnerId) {
      props.dispatch(saveEpicSession(sessionCode, partnerId))
    }
  }, [window.location.href, partnerId])

  useEffect(() => {
    const validViews = [
      'nosidebar',
      'noprogressbartop',
      'nolanding',
      'noonboarding',
      'nohelp',
      'nochat',
      'nolocale',
      'nofeedback',
      'nofoodgroups',
      'nocalories',
      'nodetails',
      'noworkflowresults'
    ]

    const storedViews = window.localStorage.getItem(constants.LOCAL_STORAGE_VIEW_KEY)
    const paramViews = getUrlParam('view')
    const activeViews = _.uniq([
      ...(paramViews || storedViews || '').split(','),
      ...(partner && [partner.code].some(el => ['montefiore', 'tangelo'].includes(el)) ? ['nolanding', 'nochat'] : []),
      ...(partner && partner.is_tangelo ? ['nosidebar', 'noprogressbartop', 'nohelp', 'nolanding', 'noonboarding', 'noresults'] : [])
    ]).filter((view) => validViews.includes(view))

    setNoLocale(activeViews.includes('nolocale'));

    const customizations = {}

    validViews.forEach((view) => {
      customizations[view] = activeViews.includes(view)
    })
    if (activeViews.join(',') !== storedViews) {
      window.localStorage.setItem(constants.LOCAL_STORAGE_VIEW_KEY, activeViews.join(','))
    }

    props.dispatch(generalActions.updateViewCustomizations(customizations))
  }, [partner, window.location.href])

  useEffect(() => {
    updateViewCustomizationClasses(viewCustomizations)
  }, [viewCustomizations])

  const [weglotInstance, setWeglotInstance] = useState(null);
  useEffect(() => {
    if (window.Weglot) {
      const instance = window.Weglot.initialize({
        api_key: 'wg_8cd218082ca06d8b93409ecb11024c581',
        translate_els: '#root',
        originalLanguage: 'en',
        destinationLanguages: 'es,vi,ar',
        hide_switcher: noLocale,
        wait_transition: true,
        cache: true
      });
      window.Weglot.on('languageChanged', (newLang) => {
        //console.log('Locale changed to:', newLang);
        postCurrentLocale(newLang).then(() => {
          //console.log('Updated User Locale to:', newLang);
        });
      });
      setWeglotInstance(instance);
      //console.log('Weglot library Loaded', instance);
    } else {
      //console.error('Weglot library not loaded');
    }
    return () => {
      if (weglotInstance) {
        weglotInstance.destroy();
      }
    };
  }, [noLocale]);

  useEffect(() => {
    if (partner) {
      initManifest(partner)

      if (nbty) {
        const link = document.createElement('link')

        link.rel = 'stylesheet'
        link.type = 'text/css'
        link.href = 'https://fonts.googleapis.com/css?family=Lato'

        document.head.appendChild(link)
      }
    }
  }, [partner])

  const theme = {
    primary: (partner || {}).primary_color,
    secondary: (partner || {}).secondary_color,
    black: nbty ? '#004438' : '#000',
    button: '',
    ...(wellcoaches ? getWellcoachesTheme() : {})
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {nbty && <NaturesBountyStyles />}
      {!refreshing && (
        <Switch>
          {partnerId && <Route path="/complete" render={(p) => <Main {...p} />} />}
          <Route render={(p) => <Page {...p} {...props} />} />
        </Switch>
      )}
      {deviceInfo.platform === 'ios' && <StatusBar />}
      {deviceInfo.platform === 'web' && ['development', 'staging'].includes(constants.ENVIRONMENT) && (
        <></>
        /*<InternationalizationBar />*/
      )}
      {deviceInfo.platform === 'web' && <NativeCTA />}
      {/*['ios', 'android'].includes(deviceInfo.platform) && <PushNotificationHandler />*/}
      {/*!localeParam && <LocaleAlert />*/}
    </ThemeProvider>
  )
}

const ConnectedApp = connect((state) => ({
  ..._.pick(state, 'general', 'shareDashboard', 'auth', 'workflow', 'userAssessment'),
  partner: state.general.partnerId ? state.data.partner[state.general.partnerId] : null,
  user: state.general.userId ? state.data.user[state.general.userId] : null
}))(App)

const isUnsubscribeURL = () => {
  return window.location.href.match(/\/communications\/unsubscribe\//)
}

const getInitParams = () => {
  const url = window.location.href // ?? []
  const urlParams = new URLSearchParams(window.location.search)

  if (url.match(/\/s\/\w+$/)) {
    return { share_token: window.location.href.split('/s/')[1].split('/')[0] }
  }

  //const subdomain = (window.location.host.match(/^([^\.]+)(\.staging)?\.(dietid\.com|localhost(\:\d+)?)$/) || [])[1]
  const subdomain = (window.location.host.match(/^([^\.]+)(\.staging)?\.(dietid\.(com|ca|us)|localhost(\:\d+)?)$/) || [])[1]
  const subdomainPartner = subdomain && !['app', 'app-staging'].includes(subdomain) ? subdomain : null
  const pathPartner = (url.match(/\/p\/([^\/]+)/) || [])[1]

  // redirect legacy partner urls
  if (pathPartner && !subdomainPartner) {
    window.location.href = window.location.href
      .replace(/\/p\/([^\/]+)/, '')
      .replace('localhost', `${pathPartner}.localhost`)
      .replace('app-staging.dietid.com', `${pathPartner}.staging.dietid.com`)
      .replace('app.dietid.com', `${pathPartner}.dietid.com`)

    return {}
  }

  const partner = subdomainPartner || pathPartner
  const user = urlParams.get('u') ?? (url.match(/\/u\/([^\/]+)/) || [])[1]
  const params = {
    ...(partner ? { partner_token: partner } : {}),
    ...(user ? { partner_user: user } : {})
  }
  //sso3: urlParams.get('sso3')

  const storedPartner = window.localStorage.getItem(constants.LOCAL_STORAGE_PARTNER_TOKEN_KEY)

  if (url.match(/\/ua\/\w+$/)) {
    return { user_assessment_token: window.location.href.split('/ua/')[1].split('/')[0], ...params }
  }

  if (params.partner_token) {
    if (!subdomainPartner) {
      window.localStorage.setItem(constants.LOCAL_STORAGE_PARTNER_TOKEN_KEY, params.partner_token)
    }

    return params
  }

  if (storedPartner) {
    return { partner_token: storedPartner }
  }

  return params
}

store.dispatch(generalActions.init(getInitParams()))

var withFonts = (ComposedComponent) => (props) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    ;(async () => {
      await Font.loadAsync({
        ionicons: require('../assets/fonts/ionicons.ttf')
      })

      setLoaded(true)
    })()
  }, [])

  if (!loaded) {
    return null
  }

  // TODO: find out if this is necessary: textPropsIOS={{ allowFontScaling: false }}

  return <ComposedComponent {...props} allowFontScaling={false} />
}

const initManifest = (partner) => {
  const el = document.querySelector('link[rel="manifest"]')

  if (!el) {
    return
  }

  const manifest = {
    name: partner.name,
    short_name: partner.name,
    description: partner.name,
    start_url: '',
    background_color: partner.primary_color || '#ffffff',
    theme_color: partner.primary_color || '#ffffff',
    dir: 'auto',
    display: 'standalone',
    lang: 'en',
    orientation: 'portrait',
    prefer_related_applications: true,
    related_applications: [],
    icons: [
      {
        src: '/icon.png',
        sizes: '144x144',
        type: 'image/png'
      }
    ]
  }

  el.setAttribute('href', URL.createObjectURL(new Blob([JSON.stringify(manifest)], { type: 'application/json' })))
}

export default withFonts(() => {
const queryClient = new QueryClient();
return (
  <QueryClientProvider client={queryClient}>
  <Provider store={store}>
    <CookieConsent />
    <ConnectedRouter history={history}>
      <Switch>
        <Route component={ConnectedApp} />
      </Switch>
    </ConnectedRouter>
  </Provider>
  </QueryClientProvider>
)
})
