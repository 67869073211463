import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Header as _Header, GrayHeader as _GrayHeader } from 'app/components/Core'
import LoadingIndicator from 'app/components/LoadingIndicator'
import Icon from 'app/components/Icon'
import colors from 'app/lib/colors'
import Progress from './Progress'
import Analytics from 'app/lib/analytics'
import animateScrollTo from 'animated-scroll-to'
import ShareInterface from 'app/components/ShareInterface'
import * as constants from 'app/lib/constants'
import { sendWorkflowProgressChange, sendMessage } from 'app/lib/integration'
import t from 'app/lib/i18n'
import * as actions from 'app/actions/workflow'

import PhotoSelection from './PhotoSelection'
import DietResults from './DietResults'
import DietTransitionResults from './DietTransitionResults'
import Screener from './Screener'
import MultiRadioSelect from './MultiRadioSelect'
import QuestionSelect from './QuestionSelect'
import QuestionMultipleSelect from './QuestionMultipleSelect'
import QuestionEmbeddedCode from './QuestionEmbeddedCode'
import Ideal from './Ideal'
import UserInfo from './UserInfo'
import NavigationWorkflow from './NavigationWorkflow'
import Onboarding from './Onboarding'
import QuestionInput from './QuestionInput'
import { signOut } from '../../../actions/auth'
import QuestionSelectDropdown from './QuestionSelectDropdown'
import QuestionAddress from './QuestionAddress'
import QuestionUSPSAddress from './QuestionUSPSAddress'
import DOBCheck from './DOBCheck'
import { sendWorkflowComplete } from '../../../lib/integration'
import CustomConsent from './CustomConsent'
import breaks from 'remark-breaks'
import ReactMarkdown from 'react-markdown'
import { redirectToNewApp } from '../../../lib/util'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;

  @media (max-width: ${constants.MOBILE_MAX_WIDTH - 50}px) {
    padding-bottom: 80px;
  }

  .share-interface {
    position: absolute;
    top: -8px;
    right: -18px;
  }

  .help-icon {
    position: absolute;
    top: 18px;
    left: 12px;
    color: ${props => props.theme.primary};
    font-size: 20px;
    cursor: pointer;
    opacity: 0.5;
  }
`

const LoadingWrap = styled.div`
  position: fixed;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    .loading-indicator {
      margin-left: 300px;
    }
  }
`

const FailWrap = styled.div`
  background-color: ${colors.white};
  padding: 20px;
  border-radius: 20px;
  text-align: center;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    margin-left: 300px;
  }

  .button {
    background-color: ${colors.darkGray};
    color: ${colors.white};
    padding: 12px 22px;
    border-radius: 20px;
    margin-top: 20px;
  }
`

const CurrentStepWrap = styled.div`
  position: relative;

  @media (min-width: ${constants.MOBILE_MAX_WIDTH - 50}px) {
    padding-bottom: 80px;
  }

  @media (min-width: ${constants.MOBILE_MAX_WIDTH}px) {
    &.navigation-step {
      margin-top: -40px;
    }
  }

  &.navigation-step {
    display: flex;
    flex: 1;
    padding: 0 !important;
    margin-bottom: -80px;
  }
`

const StepError = styled.div`
  color: red;
  padding: 25px;
  text-align: center;
`

const Header = styled(_Header)`
  font-size: 18px;
  margin: 0px 12px 15px;
  padding: 0;
`

const GrayHeaderOverflow = styled(_GrayHeader)`
  ${Header} + & {
    margin-top: -25px;
    max-width: 84%;
    max-height: 600px;
    line-height: 23px;
    overflow-y: auto;
    margin: auto;
  }
`

const GrayHeader = styled(_GrayHeader)`
  ${Header} + & {
    margin-top: -25px;
    max-width: 50%;
    max-height: 250px;
    line-height: 25px;
    overflow-y: auto;
    margin: auto;
  }
`

const SubHeading = styled.div`
  padding: 12px;
  color: ${colors.gray};
  font-size: 16px;
  text-align: center;
  max-width: 50%;
  max-height: 650px;
  overflow-y: auto;
  margin: auto;

  p {
    margin-bottom: 0px;
  }
  img {
    width: 100%;
    height: auto;
    max-width: 500px;
    display: block;
    margin: 0px auto 0px;
  }
`

class Workflow extends Component {
  handleHistoryChange = e => {
    this.props.dispatch(actions.handleBackButton(e))
  }

  constructor(props) {
    super(props)

    const userInfoTotalSteps =
      {
        confermed: 2,
        nbty: 4
      }[props.partner.code] || 1

    let sections = {
      Screener: { parent: 'Screeners', completedSteps: 0, totalSteps: 0 },
      IDPhotoSelection: { parent: 'ID', completedSteps: 0, totalSteps: 10 },
      DietRestrictionChoices: { parent: 'ID', completedSteps: 0, totalSteps: 1 },
      UserInfo: { parent: 'ID', completedSteps: 0, totalSteps: userInfoTotalSteps },
      IDResults: { parent: 'ID', completedSteps: 0, totalSteps: 1 },
      IdealInfo: { parent: 'Ideal', completedSteps: 0, totalSteps: 0 },
      IdealPhotoSelection: { parent: 'Ideal', completedSteps: 0, totalSteps: 2 },
      IdealResults: { parent: 'Ideal', completedSteps: 0, totalSteps: 1 },
      DietTransitionResults: { parent: 'Ideal', completedSteps: 0, totalSteps: 1 },
      Navigation: { parent: 'Navigation', completedSteps: 0, totalSteps: 0 },
      QuestionInput: { parent: 'Questions', completedSteps: 0, totalSteps: 1 },
      QuestionEmbeddedCode: { parent: 'Questions', completedSteps: 0, totalSteps: 1 }
    }

    if (props.partner.code === 'nbty') {
      sections = {
        Screener: { ...sections.Screener, parent: 'YourDiet' },
        IDPhotoSelection: { ...sections.IDPhotoSelection, parent: 'YourDiet' },
        DietRestrictionChoices: { ...sections.DietRestrictionChoices, parent: 'AboutYou' },
        UserInfo: { ...sections.UserInfo, parent: 'AboutYou' },
        IDResults: { ...sections.IDResults, parent: 'YourResults' }
      }
    }

    this.state = { sections }
  }

  componentDidMount() {
    if (!this.props.workflow.step && !this.props.workflow.gettingNextStep) {
      this.props.dispatch(actions.getNextStep())
    }

    window.addEventListener('popstate', this.handleHistoryChange)
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleHistoryChange)
  }

  /**
   * We can't just use window.scrollTo because we need a reference to the
   * main wrapper element here.  So this function gets passed down to children
   * so that they may call it when needed to scroll to top or elsewhere.
   **/
  scrollTo = (offset, speed = 3000) => {
    /*
    if (constants.DEVICE_IS_SMALL_SCREEN()) {
      // On mobile we scroll the whole body/window, otherwise there is some weird interference from
      // ios momentum scrolling
      animateScrollTo(offset, { speed: speed })
    } else {
      // On desktop we scroll the content container
      const el = this.props.scrollRef || window
      animateScrollTo(offset, { element: el, speed: speed })
    }
    */

    animateScrollTo(offset, { element: this.props.scrollRef() || window, speed: speed })
  }

  handleProgressChange = (section, completedSteps, totalSteps, events = []) => {
    const sections = this.state.sections

    if (!sections[section]) {
      console.error(`Section "${section}" is not defined in state sections.`)
      return
    }

    sections[section].completedSteps = completedSteps

    if (typeof totalSteps !== 'undefined') {
      sections[section].totalSteps = totalSteps
    }

    const progress = Object.values(sections)
      .filter(({ parent }) => parent === sections[section].parent)
      .reduce(
        (stats, { completedSteps, totalSteps }) => ({
          completedSteps: stats.completedSteps + completedSteps,
          totalSteps: stats.totalSteps + totalSteps
        }),
        {
          completedSteps: 0,
          totalSteps: 0
        }
      )

    this.props.dispatch(
      actions.setStepProgress({ completedSteps: progress.completedSteps, totalSteps: progress.totalSteps })
    )

    // fire extra events that occured before the step changed
    if (this.props.workflow && this.props.workflow.step && this.props.workflow.step.events) {
      if (this.props.workflow.step.events.id_webhook_fired) {
        sendMessage('id_webhook_fired', true)
      }
      if (this.props.workflow.step.events.completion_webhook_fired) {
        sendMessage('completion_webhook_fired', true)
      }
    }

    sendWorkflowProgressChange({
      section: sections[section].parent,
      percentComplete: progress.totalSteps > 0 ? progress.completedSteps / progress.totalSteps : 0,
      completedSteps: progress.completedSteps,
      totalSteps: progress.totalSteps,
      workflowID:
        this.props.workflow && this.props.workflow.step && this.props.workflow.step.workflow_id
          ? this.props.workflow.step.workflow_id
          : null,
      rawData:
        this.props.workflow && this.props.workflow.step && this.props.workflow.step.responses
          ? this.props.workflow.step.responses
          : {}
    })
  }

  handleStepSubmit = val => {
    const { workflow, partner } = this.props
    const { step } = workflow
    const signOutOnFinish = partner.sign_out_on_finish
    const showCompletionScreen = partner.show_completion_screen
    const isKioskMode = partner.is_kiosk
    const isLastStep = workflow.step.is_last_step
    const isFinished = workflow.step.finished
    const redirectURI = localStorage.getItem('DIET_ID_REDIRECT')
    const nextUrl = redirectURI ?? partner.external_link_last_step_finish_button
    //console.log('tangelo isLastStep, signOutOnFinish, showCompletionScreen, nextUrl')
    //console.log('tangelo ', isLastStep, signOutOnFinish, showCompletionScreen, nextUrl)

    const callback = advancedToNextStep => {
      if (advancedToNextStep) {
        this.scrollTo(0, 1000)
      } else {
        this.scrollTo(99999, 3000)
      }
    }
    this.props.dispatch(actions.getNextStep(val, callback))

    if (isLastStep && !showCompletionScreen) {
      sendWorkflowComplete(partner.workflow_webhook_url_hash)
    }
    if (partner?.is_tangelo && (isLastStep || isFinished)) {
      redirectToNewApp()
    }

    const sharingToken = step.sharingToken || workflow.sharingToken
    if ((isLastStep || isFinished) && isKioskMode && sharingToken) {
      let loc = window.location
      let phi_url = `${loc.protocol}//${loc.host}?detailed=1&phi=1#/s/${sharingToken}`
      window.open(phi_url, '_blank')
      const kioskCode = localStorage.getItem('kioskCode')
      window.location.href = `../../../kiosk?access_code=${kioskCode}`
    }

    //if(step?.step == 'ideal_goals' || isFinished){
    if (step?.step == 'diet_ideal' || isFinished) {
      //alert('we made it here')
      // todo final step for tangelo, but we might need more qualifiers to prevent issues for other partners
      if (isLastStep && !showCompletionScreen && signOutOnFinish) {
        console.log('logging out...')
        signOut()
      }
      if (isLastStep && !showCompletionScreen && Boolean(nextUrl)) {
        console.log('redirecting...')
        window.location.href = nextUrl
      }
    }
  }

  handleRetry = () => {
    this.handleStepSubmit(this.props.workflow.lastVal)
  }

  renderCurrentStep() {
    const { step, gettingNextStep } = this.props.workflow
    if (!step) {
      return <span />
    }

    if (this.props.partner.code === 'nbty' && step.step === 'diet_restriction_choices') {
      if (!gettingNextStep) {
        this.handleStepSubmit([])
      }

      return <CurrentStepWrap />
    }

    // Based on the type of step returned from the API
    // we render the right type of form/flow
    const Component = {
      custom_user_consent: CustomConsent,
      dob_check: DOBCheck,
      diet_screener: Screener,
      photo_selection: PhotoSelection,
      diet_results: DietResults,
      id_to_ideal_results: DietTransitionResults,
      ideal_goals: Ideal, //IdealGoals,
      multi_radio_select: MultiRadioSelect,
      question_select: QuestionSelect,
      question_select_dropdown: QuestionSelectDropdown,
      question_multiple_select: QuestionMultipleSelect,
      question_address: QuestionAddress,
      question_usps_address: QuestionUSPSAddress,
      embedded_code: QuestionEmbeddedCode,
      question_input: QuestionInput,
      user_info: UserInfo,
      navigation: NavigationWorkflow
    }[step.type]

    // If the api specified a type we aren't aware of, we break :(
    if (!Component) {
      return (
        <CurrentStepWrap>
          <StepError>
            {t('Oops, something went wrong')} <br /> ({t('Invalid step type:')} {step.type})
          </StepError>
        </CurrentStepWrap>
      )
    }
    // console.log('here is ageOfUser in index', this.props.workflow?.step?.fields?.[1]?.value)

    // Render the correct component and when it returns a result, act on it to get next step
    return (
      <CurrentStepWrap className={`${step.type}-step`}>
        <Component
          partner={this.props.partner}
          highlightColor={this.props.highlightColor}
          step={step}
          workflow={this.props.workflow}
          errors={this.props.workflow.gettingNextStepErrors}
          onProgressChange={this.handleProgressChange}
          onSubmit={this.handleStepSubmit}
          scrollRef={this.props.scrollRef}
          dispatch={this.props.dispatch}
          ageOfUser={this.props.workflow?.step?.fields?.[1]?.value}
        />
      </CurrentStepWrap>
    )
  }

  renderTitleAndHeading(step) {
    const { partner } = this.props
    let { title, heading, sub_heading: subheading } = step || {}

    //if (!step || ['diet_screener', 'navigation'].includes(step.type)) {
    if (!step || ['navigation'].includes(step.type)) {
      return {}
    }

    if ((partner || {}).code === 'nbty') {
      if (['diet_restriction_choices', 'user_info'].includes(step.step)) {
        //console.log('step has either diet restriction choices or user info')
        return {}
      }

      if (step.step === 'id_results') {
        title = 'Here is your personalized nutrient analysis.'
        heading = null
      }
    }

    if ((partner || {}).code === 'd2cfreetrial' && step.step === 'id_to_ideal_results') {
      if (step.goals && step.goals.length > 0) {
        title = 'How to Achieve Your Goal(s):'
        heading = step.goals.map(goal => goal.label).join(', ')
      } else if (step.responses.ideal_goals.for_health.length > 0 && step.diet_id.id !== step.diet_ideal.id) {
        title = 'How to Reach Your Goal:'
        heading = step.responses.ideal_goals.for_health[0].replace('other:', '')
      } else if (step.diet_id.id !== step.diet_ideal.id) {
        title = 'How to Reach Your Goal Diet:'
        heading = step.diet_ideal.name
      }
    }

    return { title, heading, subheading }
  }

  render() {
    if (
      !this.props.workflow.onboardingComplete &&
      !this.props.workflow.currentState.diet_id_id &&
      !this.props.skipOnboarding
    ) {
      return <Onboarding />
    }

    const { step, gettingNextStep, gettingNextStepFailed } = this.props.workflow

    const currStepName = step ? step.step : null
    const showShare =
      currStepName === 'id_results' || currStepName === 'ideal_results' || currStepName === 'id_to_ideal_results'

    const { title, heading, subheading } = this.renderTitleAndHeading(step)

    return (
      <Wrap {...this.props}>
        {showShare ? <ShareInterface dispatch={this.props.dispatch} workflow={this.props.workflow} /> : null}
        {!this.props.nohelp && (
          <a
            href="https://dietid.com/support"
            target="_blank"
            className="help-icon"
            rel="noopener noreferrer"
            onClick={() => {
              Analytics.trackPageView('/support')
            }}
          >
            <Icon name="help-circle" />
          </a>
        )}
        <Progress {...this.props} nomenu={!showShare && this.props.nohelp} />
        {(title || heading || subheading) && (
          <div>
            {title && (
              <Header {...this.props} id={'assessment_title'}>
                {t(title)}
              </Header>
            )}
            {heading &&
              !(this.props.partner?.code?.includes('tangelo') && this.props?.workflow?.step?.step === 'ideal_goals') &&
              !(this.props?.workflow?.step?.step === 'custom_user_consent') && (
                <>
                  {typeof heading === 'string' && heading.includes('/iframe') ? (
                    <GrayHeader
                      {...this.props}
                      id={'assessment_header'}
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: heading }} />
                    </GrayHeader>
                  ) : (
                    <GrayHeader {...this.props} id={'assessment_header'}>
                      {t(heading)}
                    </GrayHeader>
                  )}
                </>
              )}
            {/*
            {this.props?.workflow?.step?.step === 'ideal_goals' && heading && !this.props.partner?.code?.includes('tangelo') && <GrayHeader {...this.props}>{t(heading)}</GrayHeader>}
            {this.props?.workflow?.step?.step === 'custom_user_consent' && heading && !this.props.partner?.code?.includes('tangelo') && <GrayHeaderOverflow {...this.props}>{t(heading)}</GrayHeaderOverflow>}
*/}
            {subheading && (
              <SubHeading {...this.props} id={'assessment_subheader'}>
                <ReactMarkdown source={t(subheading)} plugins={[breaks]} />
              </SubHeading>
            )}
          </div>
        )}
        {this.renderCurrentStep()}
        {gettingNextStep && (
          <LoadingWrap>
            <LoadingIndicator size={22} lineWidth={2} color={colors.white} />
          </LoadingWrap>
        )}
        {gettingNextStepFailed && (
          <LoadingWrap>
            <FailWrap>
              {t('Oops, something went wrong')}
              <div className="button" onClick={this.handleRetry}>
                {t('Try Again')}
              </div>
            </FailWrap>
          </LoadingWrap>
        )}
      </Wrap>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const partner = state.general.partnerId ? state.data.partner[state.general.partnerId] : {}

  return {
    partner: partner,
    workflow: state.workflow,
    nohelp: state.general.viewCustomizations.nohelp,
    skipOnboarding: state.general.viewCustomizations.noonboarding,
    highlightColor: (state.workflow.step || {}).highlight_secondary ? partner.secondary_color : partner.primary_color
  }
}

export default connect(mapStateToProps)(Workflow)
