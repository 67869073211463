import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import ListSelection from './components/ListSelection'
import NextButton from './NextButton'

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 13px;
`

const QuestionEmbeddedCode = props => {
  const { step, onProgressChange, onSubmit } = props
  const [values, setValues] = useState(
    step.step === 'diet_restriction_choices' ? getDietRestrictionsForScreener(step.responses.diet_screener) : []
  )

  const onNextButtonClick = () => {
    onProgressChange('QuestionEmbeddedCode', 1)
    onSubmit({ key: step.fields[0].question_id, label: 'complete' })
    setValues([])
  }

  return (
    <Wrap>
      <NextButton
        {...props}
        onClick={onNextButtonClick}
        allowSkip={true}
        // disabled={!values.length && step.step !== 'diet_restriction_choices'}
      />
    </Wrap>
  )
}

export default QuestionEmbeddedCode
