import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import Icon from 'app/components/Icon'
import Input from 'app/components/Input'
import colors from 'app/lib/colors'
import t from 'app/lib/i18n'

const slideInUp = keyframes`
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`

const slideOutDown = keyframes`
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
`

const FieldLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  border-bottom: 2px solid ${props => colors.hexToRgb(props.theme.primary, 0.4)};
  padding: 10px 0;
  color: ${colors.text};

  ${props =>
    props.animate &&
    !props.animateOut &&
    css`
      animation: ${slideInUp} 500ms ${props => props.animationDelay}ms forwards;
      opacity: 0;
    `}

  ${props =>
    props.animate &&
    props.animateOut &&
    css`
      animation: ${slideOutDown} 500ms ${props => props.animationDelay}ms forwards;
      opacity: 1;
    `}
`

const Options = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`

const OptionPhoto = styled.img`
  width: 120px;
  max-height: 120px;
  margin-right: 25px;
`

const OptionContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 12px;
`

const OptionDescription = styled.div`
  font-size: 14px;
  color: ${colors.text};
  margin-top: 5px;
`

const OptionWrap = styled.li`
  list-style-type: none;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;
  padding-left: 0px;
  z-index: 1;

  ${props =>
    props.animate &&
    !props.animateOut &&
    css`
      animation: ${slideInUp} 500ms ${props => props.animationDelay}ms forwards;
      opacity: 0;
    `}

  ${props =>
    props.animate &&
    props.animateOut &&
    css`
      animation: ${slideOutDown} 500ms ${props => props.animationDelay}ms forwards;
      opacity: 1;
    `}

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    content: '';
    border-top: 1px solid ${props => colors.hexToRgb(props.theme.primary, 0.2)};
  }

  &:first-child:before {
    display: none;
  }

  &:hover {
    z-index: 2;

    > .marker {
      color: ${props => colors.hexToRgb(props.theme.primary, 1)};
    }
  }

  &.selected {
    > .marker {
      color: ${props => props.theme.primary};
    }

    > ${OptionContent} > .label .id-diet-tag {
      margin-right: 0;
    }

    &.has-description > ${OptionContent} {
      > .label {
        font-weight: bold;
        color: ${props => props.theme.primary};
      }

      > .description {
        display: block;
      }
    }
  }

  .marker {
    width: 20px;
    height: 24px;
    margin: 8px 0 0;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: ${props => colors.hexToRgb(props.theme.primary, 0.4)};

    &.ion-ios-checkbox {
      font-size: 26px;
    }

    &.ion-ios-square-outline {
      font-size: 30px;
      line-height: 34px;
      margin-top: 5px;
    }
  }

  .label {
    font-size: 15px;
    color: ${colors.text};

    .icon {
      margin-right: 8px;
      vertical-align: middle;
      font-size: 10px;
    }

    .id-diet-tag {
      float: right;
      margin-right: 20px;
      font-style: italic;
      font-size: 14px;
      line-height: 16px;
      color: ${props => props.theme.primary};
    }
  }

  .info-icon {
    position: absolute;
    top: 0;
    right: -6px;
    width: 42px;
    height: 42px;
    font-size: 16px;
    color: ${props => colors.hexToRgb(props.theme.primary, 0.6)};
    text-align: center;

    svg {
      margin: 13px auto;
    }

    &:hover {
      color: ${props => props.theme.primary};
    }
  }

  .info-icon:hover + .description {
    display: block;
    position: absolute;
    top: 32px;
    right: 0;
    left: 0;
    padding: 10px 14px;
    z-index: 1;
    background: #fff;
    box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.05), 0 2px 4px rgba(16, 22, 26, 0.1), 0 8px 24px rgba(16, 22, 26, 0.1);
    border-radius: 5px;
    pointer-events: none;
  }

  .description {
    display: none;
  }
`

const Content = styled.div`
  flex: 1;
  padding: 12px;
`

const Wrap = styled.div`
  width: calc(100% - 26px);
  max-width: 400px;
  margin: 0 20px 20px;

  &:first-child {
    margin-top: 20px;
  }

  &.with-photo {
    display: flex;
    flex-direction: row;
    align-items: center;

    ${FieldLabel} {
      font-size: 15px;
    }
  }

  ${OptionWrap} & {
    margin: 0 0 -8px;

    ${FieldLabel} {
      margin-left: 26px;
      padding: 12px 0 8px;
      font-style: italic;
      font-weight: bold;
      font-size: 14px;
      border-bottom: none;
    }

    ${OptionWrap} {
      &:before {
        left: 26px;
      }

      &:first-child:before {
        display: block;
      }

      .label {
        font-size: 14px;
      }

      .marker {
        width: 14px;
        height: 20px;
        margin: 7px 0 0;
        font-size: 18px;
        line-height: 20px;
      }
    }

    ${OptionContent} {
      padding: 9px 12px 8px;
    }
  }
`

const defaultContentRenderer = ({ label, description, isSelected }) => (
  <>
    <div className="label">{label === 'Sometimes' ? t('Occasionally') : t(label)}</div>
    {description && !isSelected && (
      <div className="info-icon">
        <Icon name="info-circle" />
      </div>
    )}
    {description && <OptionDescription className="description">{t(description)}</OptionDescription>}
  </>
)

const Option = ({
  selections,
  onChange,
  single_only,
  label,
  value: baseValue,
  description,
  placeholder,
  contentRenderer = defaultContentRenderer,
  childLists,
  mandatory,
  animate,
  animateOut,
  animationDelay
}) => {
  const [, customValues] = selections.reduce(
    ([baseSelections, customValues], selectionAndCustomValue) => {
      const [selection, customValue] =
        typeof selectionAndCustomValue === 'string' ? selectionAndCustomValue.split(':') : [selectionAndCustomValue]

      return [[...baseSelections, selection], { ...customValues, [selection]: customValue }]
    },
    [[], {}]
  )

  const getCustomizedValue = customization =>
    baseValue !== null && (customization || '').length ? `${baseValue}:${customization}` : baseValue

  const customValue = customValues[baseValue]
  const value = getCustomizedValue(customValue)
  const isSelected = value === null ? selections.length === 0 : selections.includes(value)

  const onOptionClick = e => {
    // prevent clicking on options in child lists from clicking on the parent option
    e.stopPropagation()

    if (value === null) {
      return onChange([])
    }

    // radio
    if (single_only && value && (mandatory || !selections.includes(value))) {
      return onChange([value])
    }

    // checkbox (unchecked -> checked)
    if (!selections.includes(value)) {
      return onChange([...selections, value])
    }

    // checkbox (checked -> unchecked)
    ;(childLists || []).forEach(list => list.onChange([]))
    return onChange(selections.filter(s => s !== value))
  }

  const onCustomValueChange = e => {
    const newValue = getCustomizedValue(e.target.value)
    onChange(selections.map(selection => (selection === value ? newValue : selection)))
  }

  const marker = {
    single: [
      ['radio-button-off', 'radio-button-on'],
      ['radio-button-off', 'radio-button-on'] // checkmark-circle
    ],
    multiple: [
      ['checkbox-outline', 'checkbox'],
      ['checkbox-outline', 'checkbox']
    ]
  }[single_only ? 'single' : 'multiple'][description ? 1 : 0][isSelected ? 1 : 0]

  return (
    <>
      <OptionWrap
        onClick={e => onOptionClick(e, value)}
        className={`${single_only ? 'single' : ''} ${isSelected ? 'selected' : ''} ${
          description ? 'has-description' : ''
        }`}
        animate={animate}
        animateOut={animateOut}
        animationDelay={animationDelay}
      >
        <Icon className={'marker icon'} name={marker} />
        <OptionContent>
          {contentRenderer({ value, label, description, isSelected })}
          {isSelected && childLists && childLists.map((props, idx) => <ListSelection key={idx} {...props} />)}
        </OptionContent>
      </OptionWrap>
      {baseValue && typeof baseValue === 'string' && (baseValue || '').toLowerCase() == 'other' && isSelected && (
        <Input
          type="text"
          className={customValue ? 'has-value' : null}
          placeholder={t(placeholder)}
          value={customValue || ''}
          onChange={onCustomValueChange}
          autoFocus
        />
      )}
    </>
  )
}

const ListSelection = props => {
  const {
    label,
    infoLabel,
    options,
    single_only,
    showImages,
    photoUrl,
    animate,
    animateOut,
    animationDelay = 0,
    noLabel = false
  } = props

  return (
    <>
      <Wrap className={options && showImages ? 'with-photo' : ''}>
        {options && showImages && <OptionPhoto src={photoUrl} />}
        <Content>
          {label && !noLabel && (
            <FieldLabel
              animate={animate}
              animateOut={animateOut}
              animationDelay={animationDelay + (animateOut && options.length ? options.length : 0) * 50}
            >
              {t(label)}
              {infoLabel && (
                <>
                  <br />
                  {t(infoLabel)}
                </>
              )}
            </FieldLabel>
          )}
          {options && options.length > 0 && (
            <Options className={single_only ? 'single' : ''}>
              {options.map((option, idx) => (
                <Option
                  {...props}
                  {...option}
                  key={idx}
                  animationDelay={animationDelay + (animateOut ? options.length - 1 - idx : idx + 1) * 50}
                />
              ))}
            </Options>
          )}
          {props.children}
        </Content>
      </Wrap>
    </>
  )
}

export default ListSelection
